<template>
    <Vita/>
</template>
<script>
import Vita from '@/components/Vita.vue';

export default {
  name: 'vita',
  components: {
    Vita,
  },
};
</script>
